@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";

.body {
  padding-top: pxToRem(70);
  padding-bottom: pxToRem(45);
  min-height: calc(100vh - 80px);
}

.container {
  display: flex;
  justify-content: center;
}

.login {
  margin-bottom: 20px;
  max-width: 333px;
  width: 100%;

  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: pxToRem(22);
    line-height: pxToRem(28);
  }
}

.loginButton {
  align-self: flex-end;
  background-color: var(--brand-color);
  color: var(--brand-color-foreground);
}

.disclaimer {
  font-size: pxToRem(12);
  color: rgba($color: $dark, $alpha: 0.3);
  padding: 0 0 pxToRem(12) 0;

  a {
    font-size: pxToRem(12);
    color: rgba($color: $dark, $alpha: 0.3);
    text-decoration: underline;
  }
}
